import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logoImage from '../images/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FaHome } from "react-icons/fa";
import '../styles/Navbar.css';

const Navbar = ({ level, subject, topic }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  // const level = useSelector((state) => state.data.level);
  // const subject = useSelector((state) => state.data.subject);  
  // const topic = useSelector((state) => state.data.topic);
  const streak = useSelector((state) => state.progress.streak);
  const lastStreakDate = useSelector((state) => new Date(state.progress.lastStreakDate));
  const isSubtopicsCovered = useSelector(
    (state) => state.progress?.subtopicsCovered && Object.keys(state.progress.subtopicsCovered).length > 0
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const today = new Date();
  const isStreakActive = lastStreakDate.toDateString() === today.toDateString();
  const handleStreakClick = () => {
    if (isSubtopicsCovered) navigate('/streakpage');
    else alert('Please complete atleast one module to unlock this')
  }

  return (
    <nav className={`custom-navbar ${menuOpen ? 'open' : ''}`}>
      <div onClick={()=>navigate('/')}> <FaHome /> </div>
      <a href="https://mrsivasciencetutoring.co.uk" className="custom-navbar-logo">
        <img src={logoImage} className="custom-navbar-logo-img" alt="Logo" />
        <p className="custom-navbar-logo-text">MrSivaScience Tutoring</p>
      </a>
      <div className="custom-navbar-center">     
        {level && (
          <>
            <span
              className="breadcrumb-link"
              onClick={() => handleNavigate('/')}
            >
              {level}
            </span>
            {subject && (
              <>
                {'  ||  '}
                <span
                  className="breadcrumb-link"
                  onClick={() => handleNavigate('/picksubject')}
                >
                  {subject}
                </span>
              </>
            )}
            {topic && (
              <>
                {'   ||   '}
                <span
                  className="breadcrumb-link"
                  onClick={() => handleNavigate('/picktopic')}
                >
                  {topic}
                </span>
              </>
            )}
          </>
        )}
        <span className={`streak-display ${isStreakActive ? 'active' : 'inactive'}`} onClick={()=>handleStreakClick()}>
            <FontAwesomeIcon icon={faFire} className="streak-icon" />
            {streak}
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
