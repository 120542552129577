import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom'; 
import ErrorReportPopup from './ErrorReportPopUp';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ItemType = 'ANSWER';

const DraggableAnswer = ({ text, index, selectedAnswer, onSelectAnswer }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index, text },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    // Change background color based on selection
    const isSelected = selectedAnswer === text;

    return (
        <div
            ref={drag}
            onClick={() => onSelectAnswer(text)}  // Select the answer on click or touch
            className={`p-2 flex items-center justify-center rounded-lg cursor-pointer w-full ${
                isDragging ? 'opacity-50' : 'opacity-100'
            } ${isSelected ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}  // Change to blue when selected
            style={{
                minHeight: '80px',
                padding: '6px',  // Adjust padding to fit more text
                fontSize: '0.875rem',  // Slightly smaller font size
                wordWrap: 'break-word',  // Allow text to wrap to next line
                whiteSpace: 'normal',  // Ensure text wraps within the box
            }}
        >
            {text}
        </div>
    );
};

const DroppableArea = ({ acceptedAnswer, onDrop, index, isCorrect, isIncorrect, selectedDropIndex, onTouchDrop }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemType,
        drop: (item) => {
            if (!acceptedAnswer) {
                onDrop(item, index);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isEmpty = !acceptedAnswer;
    const isActive = selectedDropIndex === index;

    return (
        <div
            ref={drop}
            onClick={() => onTouchDrop(index)}
            className={`p-2 rounded-lg w-full h-12 flex items-center justify-center border-2 ${
                isCorrect ? 'border-green-500' : isIncorrect ? 'border-red-500' : 'border-gray-300'
            } ${isActive ? 'bg-blue-500 text-white' : isEmpty ? 'bg-gray-700 text-gray-400' : 'bg-gray-800 text-white'}`}
            style={{
                minHeight: '80px',
                padding: '6px',  // Adjust padding to fit more text
                fontSize: '0.875rem',  // Slightly smaller font size
                wordWrap: 'break-word',  // Allow text to wrap to next line
                whiteSpace: 'normal',  // Ensure text wraps within the box
            }}
        >
            {acceptedAnswer || 'Tap to place your answer'}
        </div>
    );
};

const MatchTheFollowingQuestion = ({ object, progressBar, onAnswerCheck }) => {
    const { question, options, answer } = object;
    const [droppedAnswers, setDroppedAnswers] = useState(Array(answer.length).fill(null));
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [correctIndexes, setCorrectIndexes] = useState([]);
    const [showNextButton, setShowNextButton] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const userId = useSelector((store)=>store.data.userId);;
    const [selectedAnswer, setSelectedAnswer] = useState(null);  // State to store selected answer
    const [selectedDropIndex, setSelectedDropIndex] = useState(null);  // State to store selected drop area

    const navigate = useNavigate();

    useEffect(() => {
        setDroppedAnswers(Array(answer.length).fill(null));
        setShuffledAnswers(shuffleArray([...answer]));
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
        setSelectedAnswer(null);
        setSelectedDropIndex(null);
    }, [options, answer]);

    const shuffleArray = (array) => {
        let newArray = [...array];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        return newArray;
    };

    const handleDrop = (item, index) => {
        const updatedDroppedAnswers = [...droppedAnswers];
        const updatedShuffledAnswers = shuffledAnswers.filter((ans) => ans !== item.text);

        const previousIndex = droppedAnswers.indexOf(item.text);
        if (previousIndex !== -1) {
            updatedDroppedAnswers[previousIndex] = null;
        }

        updatedDroppedAnswers[index] = item.text;
        setDroppedAnswers(updatedDroppedAnswers);
        setShuffledAnswers(updatedShuffledAnswers);
    };

    const handleTouchDrop = (index) => {
        setSelectedDropIndex(index);
        if (selectedAnswer) {
            const updatedDroppedAnswers = [...droppedAnswers];
            updatedDroppedAnswers[index] = selectedAnswer;
            setDroppedAnswers(updatedDroppedAnswers);
            setSelectedAnswer(null);
        }
    };

    const handleCheckAnswer = () => {
        let correct = [];
        droppedAnswers.forEach((answer, index) => {
            const cleanedAnswer = answer?.toLowerCase().replace(/\s+/g, '');
            const cleanedCorrectAnswer = object.answer[index]?.toLowerCase().replace(/\s+/g, '');
        
            if (cleanedAnswer === cleanedCorrectAnswer) {
                correct.push(index);
            }
        });
        
        setCorrectIndexes(correct);
        setIsChecked(true);
        const wasCorrect = correct.length === object.answer.length;
        setFeedback(wasCorrect);
        setShowNextButton(true);
    };

    const handleNext = () => {
        const correct = droppedAnswers.every((answer, index) => answer === object.answer[index]);
        if (correct) {
            onAnswerCheck(true);
        } else {
            onAnswerCheck(false);
        }
    };

    const handleReset = () => {
        setDroppedAnswers(Array(answer.length).fill(null));
        setShuffledAnswers(shuffleArray([...answer]));
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
        setSelectedAnswer(null);
        setSelectedDropIndex(null);
    };

    const handleReportSubmit = async (reportText, questionObject) => {
        try {
            axios.post(`${process.env.REACT_APP_BE_URL}/task/report-mistake`, { report: reportText, question: questionObject , userId: userId});
            alert("Report has been submitted successfully, we will look into this as soon as possible! Thank you")
        } catch (error) {
            console.error("Error submitting report:", error);
        }
    };

    let imageIndex = 0;

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="p-5 text-center bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center">
                <div className="absolute top-5 right-5">
                    <button onClick={() => setIsPopupOpen(true)} className="text-yellow-500 text-2xl" title="Report the question" >
                        ❗
                    </button>
                </div>

                <ErrorReportPopup
                    isOpen={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                    onSubmit={handleReportSubmit}
                    questionObject={object}
                />

                <div className="w-2/3 bg-gray-200 rounded-full h-2.5 mb-4 absolute top-10">
                    <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${progressBar}%` }}> </div>
                </div>

                <div className="w-full max-w-4xl">
                    {/* Question Area */}
                    <div className="mb-5">
                        <div className="text-lg font-semibold mb-2">
                            {object.question.split(/({image\d+})/g).map((part, index) => {
                                if (part.match(/{image\d+}/)) {
                                    const match = part.match(/{image(\d+)}/);
                                    if (match) {
                                        const imageKey = imageIndex;
                                        const imageUrl = object.image ? object.image[imageKey] : null;
                                        if (imageUrl) {
                                            const imageElement = (
                                                <img
                                                    key={`image-${imageIndex}`}
                                                    src={imageUrl}
                                                    alt={`Image ${imageKey}`}
                                                    className="inline-block mx-2"
                                                />
                                            );
                                            imageIndex += 1;
                                            return imageElement;
                                        }
                                    }
                                } else {
                                    return part;
                                }
                                return null;
                            })}
                        </div>
                    </div>

                    {/* Drag-and-Drop Grid */}
                    <div className="grid grid-cols-3 gap-4 mb-5">
                        {/* Column 1: Questions */}
                        <div className="flex flex-col space-y-2">
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    className={`p-2 bg-gray-700 text-white rounded-lg border-2 w-full flex items-center justify-center ${
                                        isChecked && correctIndexes.includes(index) ? 'border-green-500' : ''
                                    }`}
                                    style={{ minHeight: '70px' }}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>

                        {/* Column 2: Drop Area */}
                        <div className="flex flex-col space-y-2">
                            {answer.map((_, index) => (
                                <DroppableArea
                                    key={index}
                                    acceptedAnswer={droppedAnswers[index]}
                                    onDrop={(item) => handleDrop(item, index)}
                                    index={index}
                                    onTouchDrop={handleTouchDrop}
                                    selectedDropIndex={selectedDropIndex}
                                    isCorrect={isChecked && correctIndexes.includes(index)}
                                    isIncorrect={isChecked && !correctIndexes.includes(index)}
                                />
                            ))}
                        </div>

                        {/* Column 3: Draggable Answers */}
                        <div className="flex flex-col space-y-2">
                            {isChecked ? (
                                answer.map((ans, index) => (
                                    <div
                                        key={index}
                                        className={`p-2 bg-gray-700 text-white rounded-lg border-2 flex items-center justify-center ${
                                            correctIndexes.includes(index)
                                                ? 'border-green-500 bg-green-400 font-medium text-black'
                                                : 'border-red-500 bg-red-400 font-medium text-black'
                                        }`}
                                        style={{ minHeight: '70px' }}
                                    >
                                        {ans}
                                    </div>
                                ))
                            ) : (
                                shuffledAnswers.map((answer, index) => (
                                    <DraggableAnswer
                                        key={index}
                                        index={index}
                                        text={answer}
                                        selectedAnswer={selectedAnswer}
                                        onSelectAnswer={setSelectedAnswer}
                                    />
                                ))
                            )}
                        </div>
                    </div>                    
                </div>
                {/* Feedback and Controls */}
                <div className="mt-5 flex-col space-y-4 items-left bottom-14 fixed">
                {feedback && <p className="text-green-500 mb-4 font-semibold">Great job!</p>}
                {!feedback && isChecked && <p className="text-red-500 mb-4 font-semibold">Incorrect!</p>}
                   
                    {showNextButton ? (
                        <div >
                            <button
                                className="px-6 py-2 bg-amber-600 text-white font-semibold rounded-lg hover:bg-amber-700 w-24 mr-5"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                            <button
                                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 w-24 "
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        </div>
                    ) : (<>
                        <button
                                className="px-6 py-2 bg-amber-600 text-white font-semibold rounded-lg hover:bg-amber-700 w-24 mr-5"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                        <button
                            className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 w-24 "
                            onClick={()=>handleCheckAnswer()}
                        >
                            Check
                        </button>
                        </>
                    )}
                    
                </div>
            </div>
        </DndProvider>
    );
};

export default MatchTheFollowingQuestion;
