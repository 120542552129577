import { combineReducers } from 'redux';
import dataReducers from './dataReducers';
import progressReducer from './progressReducers';

const rootReducer = combineReducers({
    data: dataReducers,
    progress: progressReducer,
});

export default rootReducer;
