import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const processFetchedData = (jsonData) => {
    const subjectsSet = new Set();
    const subtopicsMap = new Map();
    const modulesMap = new Map();

    jsonData?.forEach(item => {
        const { subject, topic, subtopic, module } = item;

        subjectsSet.add(subject);

        if (!subtopicsMap.has(topic)) {
            subtopicsMap.set(topic, new Set());
            modulesMap.set(topic, new Map());
        }
        subtopicsMap.get(topic).add(subtopic);

        if (!modulesMap.get(topic).has(subtopic)) {
            modulesMap.get(topic).set(subtopic, new Set());
        }
        modulesMap.get(topic).get(subtopic).add(module);
    });

    const groupedData = Object.fromEntries(
        Array.from(subtopicsMap.entries()).map(([topic, subtopics]) => [topic, [...subtopics]])
    );

    const modulesData = Object.fromEntries(
        Array.from(modulesMap.entries()).map(([topic, subtopics]) => [
            topic,
            Object.fromEntries(Array.from(subtopics.entries()).map(([subtopic, modules]) => [subtopic, [...modules]]))
        ])
    );

    const uniqueSubjects = [...subjectsSet];

    return { groupedData, modulesData, uniqueSubjects };
};

const useFetchData = (filters = {}) => {
    const [groupedData, setGroupedData] = useState({});
    const [modulesData, setModulesData] = useState({});
    const [uniqueSubjects, setUniqueSubjects] = useState([]);

    const fetchData = useCallback(async () => {
        // console.log("useFetchData: fetchData called");
        try {
            const queryParams = new URLSearchParams(filters).toString();
            const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task?${queryParams}`);
            const jsonData = response.data;


            const { groupedData, modulesData, uniqueSubjects } = processFetchedData(jsonData);

            setGroupedData(groupedData);
            setModulesData(modulesData);
            setUniqueSubjects(uniqueSubjects);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [filters]);

    useEffect(() => {
        fetchData();
    }, []);

    return { groupedData, modulesData, uniqueSubjects };
};

export default useFetchData;
