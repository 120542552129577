import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTopic, setSubject } from '../redux/actions/dataActions';
import '../styles/Sidebar.css';

const Sidebar = ({ elements, onSelectElement }) => {
    const dispatch = useDispatch();
    const selectedSubtopic = useSelector((state) => state.data.subtopic);
    const selectedTopic = useSelector((state) => state.data.topic);
    const selectedSubject = useSelector((state) => state.data.subject);
    
    const handleSelectElement = (element) => {
        if (selectedSubject && !selectedTopic) {
            dispatch(setSubject(null));
        } else if (selectedTopic && !selectedSubtopic) {
            dispatch(setTopic(null));
        }
        onSelectElement(element);
    };

    return (
        <div className="sidebar">
            <button 
                className="mt-5 px-6 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600"
                onClick={() => {
                    if (selectedSubject) {
                        dispatch(setSubject(null));
                    }
                }}
            >
                Subjects
            </button>
            {elements.map((element) => (
                <div 
                    key={element} 
                    className="sidebar-item" 
                    onClick={() => handleSelectElement(element)}
                >
                    {element}
                </div>
            ))}
        </div>
    );
};

export default Sidebar;
