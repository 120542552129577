import React from 'react';
import '../styles/index.css';

const ClassOption = ({ label, isSelected, onSelect }) => {
    return (
        <div
            className={`goal-option ${isSelected ? 'bg-purple-800' : 'bg-gray-700'} rounded-lg p-4 flex items-center justify-center my-2 cursor-pointer hover:bg-purple-700`}
            onClick={() => onSelect(label)}
        >
            <label className="cursor-pointer text-white font-semibold">
                {label}
            </label>
        </div>
    );
};

export default ClassOption;
