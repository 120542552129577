import { 
    SET_LEVEL, 
    SET_SUBJECT, 
    SET_TOPIC, 
    SET_SUBTOPIC, 
    SET_MODULE, 
    RESET_STATE, 
    SET_USERID 
} from '../actions/dataActions';

const initialState = {
    userId: localStorage.getItem('userId') || '', 
    level: '',
    subject: '',
    topic: '',
    subtopic: '',
    module: '',
};

const dataReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERID:
            localStorage.setItem('userId', action.payload); 
            return {
                ...state,
                userId: action.payload,
                level: '',   
                subject: '',
                topic: '',
                subtopic: '',
                module: '',
            };
        case SET_LEVEL:
            return {
                ...state,
                level: action.payload,
                subject: '',  
                topic: '',
                subtopic: '',
                module: '',
            };
        case SET_SUBJECT:
            return {
                ...state,
                subject: action.payload,
                topic: '',    
                subtopic: '',
                module: '',
            };
        case SET_TOPIC:
            return {
                ...state,
                topic: action.payload,
                subtopic: '', 
                module: '',
            };
        case SET_SUBTOPIC:
            return {
                ...state,
                subtopic: action.payload,
                // module: '',   // Do not erase the module
            };
        case SET_MODULE:
            return {
                ...state,
                module: action.payload,
            };
        case RESET_STATE:
            localStorage.clear();
            return initialState;
        default:
            return state;
    }
};

export default dataReducers;
