import React, { useState, useEffect, useMemo } from 'react';
import ErrorReportPopup from './ErrorReportPopUp';
import axios from 'axios';
import { useSelector } from 'react-redux';

const SingleCorrect = ({ object, progressBar, onAnswerCheck }) => {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const userId = useSelector((store)=>store.data.userId);;


    const shuffledOptions = useMemo(() => {
        const shuffleArray = (array) => {
            let newArray = [...array];
            for (let i = newArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
            }
            return newArray;
        };
        return shuffleArray(object.options);
    }, [object.options]);

    useEffect(() => {
        setSelectedAnswers([]);
        setIsAnswerChecked(false);
    }, [object]);

    const preprocessAnswer = (answer) => {
        return answer.toLowerCase().replace(/\s+/g, '');
    };

    const handleAnswerSelect = (option) => {
        if (!isAnswerChecked) {
            setSelectedAnswers([option]);
        }
    };

    const handleCheckAnswer = () => {
        setIsAnswerChecked(true);
    };

    const arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((val, index) => preprocessAnswer(val) === preprocessAnswer(arr2[index]));
    };

    const handleNext = () => {
        if (!isAnswerChecked) {
            console.warn("Answer hasn't been checked yet.");
            return;
        }

        const isCorrect = arraysAreEqual(selectedAnswers, object.answer);
        onAnswerCheck(isCorrect);
        if(!isCorrect){
            setSelectedAnswers([]);
            setIsAnswerChecked(false);
        }
    };

    const isCorrectOption = (option) => {
        return object.answer.some(correctAnswer => preprocessAnswer(correctAnswer) === preprocessAnswer(option));
    };

    const handleReportSubmit = async (reportText, questionObject) => {
        try {
            axios.post(`${process.env.REACT_APP_BE_URL}/task/report-mistake`, { report: reportText, question: questionObject , userId: userId});
            alert("Report has been submitted successfully, we will look into this as soon as possible! Thank you")
        } catch (error) {
            console.error("Error submitting report:", error);
        }
    };
      

    let imageIndex = 0;

    return (
        <div className="p-5 text-center bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center">
            <div className="absolute top-5 right-5">
                <button onClick={() => setIsPopupOpen(true)} className="text-yellow-500 text-2xl">
                    ❗
                </button>
            </div>

            <ErrorReportPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSubmit={handleReportSubmit}
                questionObject={object}
            />

            <div className="w-2/3 bg-gray-200 rounded-full h-2.5 mb-4 absolute top-10">
                <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${progressBar}%` }}> </div>
            </div>

            <div className="w-full max-w-lg">              
                <div className="mb-5">
                    <div className="text-lg font-semibold mb-2">
                        {object.question.split(/({image\d+})/g).map((part, index) => {
                            if (part.match(/{image\d+}/)) {
                                const match = part.match(/{image(\d+)}/);
                                if (match) {
                                    const imageKey = imageIndex;
                                    const imageUrl = object.image ? object.image[imageKey] : null;
                                    if (imageUrl) {
                                        const imageElement = (
                                            <img
                                                key={`image-${imageIndex}`}
                                                src={imageUrl}
                                                alt={`Image ${imageKey}`}
                                                className="inline-block mx-2 "
                                            />
                                        );
                                        imageIndex += 1;
                                        return imageElement;
                                    }
                                }
                            } else {
                                return part;
                            }
                            return null;
                        })}
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-5">
                    {shuffledOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`py-2 px-4 rounded-lg border ${
                                isAnswerChecked
                                    ? isCorrectOption(option)
                                        ? 'border-green-500 bg-green-500 text-black font-semibold'
                                        : selectedAnswers.includes(option)
                                        ? 'border-red-500 bg-red-500 text-black font-semibold'
                                        : 'border-gray-300 bg-gray-800 text-white'
                                    : selectedAnswers.includes(option)
                                    ? 'border-blue-500 bg-gray-800 text-white'
                                    : 'border-gray-300 bg-gray-800 text-white hover:bg-gray-700'
                            }`}
                            onClick={() => handleAnswerSelect(option)}
                            disabled={isAnswerChecked}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>
            <div className="mt-5 fixed bottom-14">
                    {!isAnswerChecked ? (
                        <button
                            className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                            onClick={handleCheckAnswer}
                            disabled={selectedAnswers.length === 0}
                        >
                            Check
                        </button>
                    ) : (
                        <>
                            <div className="text-green-400 mb-4 font-semibold">
                                {arraysAreEqual(selectedAnswers, object.answer) 
                                    ? progressBar === 100 ? <p>Congratulations! You have completed the module. <br/> Redirecting to the main page ..</p>
                                    : <p>Great job! </p>
                                    : <p className="text-red-500 mb-4">Incorrect! 
                                    {!!object.ref.length && object.ref[0]!=="" && (
                                            <div>
                                                <h3 className="text-lg font-medium mb-2 text-yellow-300">Explanation:</h3>
                                                {object?.ref?.map((refp, index) => (
                                                    <p key={index} className="text-gray-200 italic mb-1">{refp}</p>
                                                ))}
                                            </div>
                                        )} 
                                    </p> }
                            </div>
                            <button
                                className="mt-5 px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        </>
                    )}
                </div>
        </div>
    );
};

export default SingleCorrect;
