export const SET_PROGRESS = 'SET_PROGRESS';
export const UPDATE_STREAK = 'UPDATE_STREAK';
export const RESET_PROGRESS = 'RESET_PROGRESS';

// Action creator to set the entire progress data
export const setProgress = (progressData) => ({
    type: SET_PROGRESS,
    payload: progressData,
});

// Action creator to update the streak (e.g., if a user completes a module today)
export const updateStreak = (streakData) => ({
    type: UPDATE_STREAK,
    payload: streakData,
});

// Action creator to reset progress, if needed (e.g., start over)
export const resetProgress = () => ({
    type: RESET_PROGRESS,
});
