import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import '../../styles/Streak-click/StreakPage.css';
import Navbar from '../Navbar';


const StreakPage = () => {
    const navigate = useNavigate();
    const progress = useSelector((state) => {
      const { subtopicsCovered } = state.progress;
  
      // Create a new object with filtered key-value pairs
      const filteredProgress = Object.keys(subtopicsCovered).reduce((acc, key) => {
          // Exclude the 'Random' and 'Cummulative' keys
          // console.log("acc", acc);
          if (key !== 'Random' && key !== 'Cummulative') {
              acc[key] = subtopicsCovered[key];
          }
          return acc;
      }, {});
  
      return filteredProgress;
  });
  
    // console.log(progress);
    const [showProgress, setShowProgress] = useState(false);
    const [selectedSubtopics, setSelectedSubtopics] = useState({});

    const handleNextPage = () => {
      if (showProgress) {
          if (Object.keys(selectedSubtopics).length==0){
            alert("please choose atleast one topic from the list");
            return;
          }
          const filteredProgress = Object.fromEntries(
              Object.entries(progress).filter(
                  ([key]) => selectedSubtopics[key] === true
              )
          );
          // console.log(filteredProgress);
          
          navigate('/selectedqstreakpage', { state: {"selectedSubtopics":filteredProgress }});
      }else navigate('/selectedqstreakpage', { state:  {"selectedSubtopics":progress } })
    };  
    const handleNextPageR = () => {
      navigate('/selectedqstreakpage', { state:  {"selectedSubtopics":progress } })
    };  

    const handleProgressClick = () => {
      setShowProgress(!showProgress);  
    };
  
  

    const chooseTopics = () => {
      if (!progress || progress.length === 0) {
        return <div>No subtopics available to generate questions!</div>;
      }    
      return (
        <div className="streak-page-subtopics-wrapper">
          <div className="streak-page-subtopics-container">
            {Object.keys(progress).map((subtopic, index) => (
              <div
                key={index}
                onClick={() => {
                  const updatedSelectedSubtopics = { ...selectedSubtopics };
                  updatedSelectedSubtopics[subtopic] = !updatedSelectedSubtopics[subtopic];
                  setSelectedSubtopics(updatedSelectedSubtopics);
                }}
                className={`subtopic-option ${selectedSubtopics[subtopic] ? 'selected' : ''}`}
              >
                {subtopic}
              </div>
            ))}
          </div>

          <div className="streak-page-generate-button" onClick={handleNextPage}>
            Generate
          </div>
        </div>
      );
    };
  


    return (
        <>
        <Navbar/>
      {!showProgress ? (
          <>
          <div className='streak-page-container'>
             <div
                className="streak-page-container-b"
                onClick={() => handleNextPageR()}
              >
                {/* <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-gradient-to-b from-[#4B3F72] to-[#0A1E3D] group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-white border-2 border-[#008B8B] group-hover:bg-[#2E1A47]"></span>
                <span className="relative text-[#008B8B] group-hover:text-white"> */}
                  Generate random questions from topics I have completed
                {/* </span> */}
              </div>
              <div
                className="streak-page-container-b"
                onClick={() => handleProgressClick()}
              >
                {/* <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-gradient-to-b from-[#4B3F72] to-[#0A1E3D] group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-white border-2 border-[#008B8B] group-hover:bg-[#2E1A47]"></span>
                <span className="relative text-[#008B8B] group-hover:text-white"> */}
                  Generate custom quiz
                {/* </span> */}
              </div>
          </div>
          </>
      ) : (
        <>
          <div className='streak-page-container'>
              <div className='streak-page-container-b' onClick={() => handleNextPageR()}>Generate random questions from topics I have completed</div>
              {chooseTopics()}
          </div>
        </>
      )}
      </>
  );  
}

export default StreakPage