import { SET_PROGRESS, UPDATE_STREAK, RESET_PROGRESS } from '../actions/progressActions';

const initialState = {
    subtopicsCovered: {},
    streak: 0 ,
    maxStreak: 0 ,
    lastStreakDate: "1970-01-01T00:00:00Z" ,
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROGRESS:
            // console.log(action.payload);
            return {
                ...state,
                subtopicsCovered: action.payload.subtopicsCovered || state.subtopicsCovered,
                streak: action.payload.streak || state.streak,
                maxStreak: action.payload.maxStreak || state.maxStreak,
                lastStreakDate: action.payload.lastStreakDate || state.lastStreakDate,
            };
            
        case UPDATE_STREAK:
        //     localStorage.setItem('streak', action.payload.streak);
        //     localStorage.setItem('maxStreak', Math.max(state.maxStreak, action.payload.streak));
            
            return {
                ...state,
                subtopicsCovered: action.payload.subtopicsCovered || state.subtopicsCovered,
                streak: action.payload.streak,
                maxStreak: Math.max(state.maxStreak, action.payload.streak),
                lastStreakDate: action.payload.lastStreakDate,
            };
        case RESET_PROGRESS:
            return initialState; 
        default:
            return state;
    }
};

export default progressReducer;
