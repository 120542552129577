import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClassOption from './ClassOption';
import { setTopic } from '../redux/actions/dataActions';
import '../styles/index.css';
import axios from 'axios';
import Navbar from './Navbar';

const TopicPicker = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedLevel = useSelector((state) => state.data.level);
    const selectedSubject = useSelector((state) => state.data.subject);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BE_URL}/task/unique_topic`, {
                params: { level: selectedLevel, subject: selectedSubject },
            })
            .then((res) => {
                setTopics(res.data);
            })
            .catch((error) => {
                console.error('Error fetching topics:', error);
            });
    }, [selectedLevel, selectedSubject]);

    const handleSelect = (label) => {
        if (selectedTopic === label) {
            // If the same item is clicked, unselect it
            setSelectedTopic(null);
            dispatch(setTopic(null));
        } else {
            setSelectedTopic(label);
            dispatch(setTopic(label));
            navigate('/maincontent');
        }
    };

    const goToMainContent = () => {
        if (selectedTopic) {
            navigate('/maincontent');
        } else {
            alert('Please choose one');
        }
    };

    return (
        <>
            <Navbar level={selectedLevel} subject={selectedSubject} />
            <div className="p-5 rounded-lg shadow-lg max-w-md mt-40 mx-auto text-center font-semibold bg-gray-1000 text-[white]">
                <h3 className="mb-5 text-xl">Pick a Topic</h3>
                {topics.map((topic, index) => (
                    <ClassOption
                        key={index}
                        label={topic}
                        isSelected={selectedTopic === topic}
                        onSelect={() => handleSelect(topic)}
                    />
                ))}
                {/* {topics.length > 0 && (
                    <button
                        onClick={goToMainContent}
                        className="bg-green-500 text-white border-none py-2 px-4 mt-5 cursor-pointer rounded-lg text-md hover:bg-green-600"
                    >
                        Set Topic
                    </button>
                )} */}
            </div>
        </>
    );
};

export default TopicPicker;
