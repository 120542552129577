import React, { useState } from 'react';

const ErrorReportPopup = ({ isOpen, onClose, onSubmit, questionObject }) => {
    const [errorReport, setErrorReport] = useState("");

    const handleSubmit = () => {
        onSubmit(errorReport, questionObject);
        setErrorReport("");  // Clear input after submission
        onClose();
    };

    if (!isOpen) return null;  

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  text-black">
            <div className="bg-white p-5 rounded-lg max-w-md w-full">
                <h3 className="text-lg font-semibold mb-3">Report Issue</h3>
                <p> Is there any mistake in this question? </p>
                <textarea
                    className="w-full p-2 border rounded mb-4"
                    placeholder="Please describe the issue directly here..."
                    value={errorReport}
                    onChange={(e) => setErrorReport(e.target.value)}
                    onInput={(e) => {
                        e.target.style.height = "auto";  // Reset height first
                        e.target.style.height = `${e.target.scrollHeight}px`;  // Set height to scrollHeight
                    }}
                    style={{
                        resize: "none",
                        overflowY: "auto",
                        maxHeight: "60vh", 
                    }}
                />
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={handleSubmit}
                >
                    Submit
                </button>
                <button
                    className="ml-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ErrorReportPopup;
