import React, { useState, useEffect, useCallback , useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setModule } from '../redux/actions/dataActions';
import MCQ from './MCQ';
import FillInTheBlankQuestion from './FIB';
import SingleCorrect from './SingleCorrect';
import MatchTheFollowingQuestion from './MatchTheFollowingQuestion';
import MultiMatchQuestion from './MultiMatchQuestion';
import CompletionModal from './CompletionModal';
import { updateStreak } from '../redux/actions/progressActions';

const ModulePage = () => {
    const [incorrect, setIncorrect] = useState([]);
    const [currentObject, setCurrentObject] = useState(null);
    const [objects, setObjects] = useState([]);
    const [startTime, setStartTime] = useState(null); // Track the start time

    const selectedTopic = useSelector((state) => state.data.topic);
    const selectedSubtopic = useSelector((state) => state.data.subtopic);
    const selectedModule = useSelector((state) => state.data.module);
    let streak = useSelector((state)=>state.progress.streak);
    let maxStreak = useSelector((state)=>state.progress.maxStreak);
    let lastStreakDate = new Date(useSelector((state) => state.progress.lastStreakDate));
    // console.log(lastStreakDate);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.data.userId);
    const [showCompletionModal, setShowCompletionModal] = useState(false);
    const incorrectSet = useRef(new Set());

    const progress = objects.length > 0 ? ((objects.length - incorrect.length) / objects.length) * 100 : 0;

    const createFinalObjArr = (objects, incorrectSet, userId) => {
        const finalObjArr = objects.map((obj) => ({
            userId: userId,
            QId: obj._id, 
            // date: new Date(),
            level: obj.level || "",
            subject: obj.subject || "",
            topic: obj.topic || "",
            subtopic: obj.subtopic || "",
            module: obj.module || "",
            type: obj.type || "",
            isCorrect: !incorrectSet.has(obj._id), 
        }));
    
        return finalObjArr;
    };

    const closeModal = () => {
        setShowCompletionModal(false);
        navigate('/maincontent');
    };

    // Fetch module questions
    useEffect(() => {
        const fetchObjects = async () => {
            if (!selectedSubtopic || !selectedModule) {
                console.warn('Subtopic or Module not selected');
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_URL}/task`, {
                    params: {
                        topic: selectedTopic,
                        subtopic: selectedSubtopic,
                        module: selectedModule,
                    },
                });
                const fetchedObjects = response.data;

                if (fetchedObjects.length === 0) {
                    console.log("Completed the module");
                    navigate('/maincontent');
                    return;
                }

                setObjects(fetchedObjects);
                // console.log(fetchedObjects);
                setIncorrect(fetchedObjects);
                setStartTime(new Date());
                pickRandomObject(fetchedObjects);
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        };

        fetchObjects();
    }, [selectedSubtopic, selectedModule, navigate]);

    // Pick a random question object from the list
    const pickRandomObject = useCallback((objects) => {
        if (objects.length > 0) {
            const randomIndex = Math.floor(Math.random() * objects.length);
            setCurrentObject(objects[randomIndex]);
        } else {
            navigate('/maincontent');
        }
    }, [navigate]);

    // Handle adding module progress API
    const addModuleProgress = async () => {
        try {
            const today = new Date();
            const todayDateOnly = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

            // Convert lastStreakDate to GMT date-only format
            // if (isNaN(lastStreakDate)) {
            //     lastStreakDate = new Date("1970-01-01T00:00:00Z"); // Default old date
            // }
            lastStreakDate = new Date(Date.UTC(
                lastStreakDate.getUTCFullYear(),
                lastStreakDate.getUTCMonth(),
                lastStreakDate.getUTCDate()
            ));

            const differenceInTime = todayDateOnly - lastStreakDate;
            const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
            console.log(`Difference in days: ${differenceInDays}`);
            // console.log(todayDateOnly);
            if (differenceInDays ===1 ) {
                streak += 1;
            } else if (differenceInDays >= 2) {
                streak = 1;
            }
            maxStreak = Math.max(maxStreak, streak);

            await axios.patch(`${process.env.REACT_APP_BE_URL}/progress/${userId}/add-module`, {
                subtopic: selectedSubtopic, moduleToAdd: selectedModule,
                streak: streak, maxStreak:maxStreak, lastStreakDate:todayDateOnly
            });
            dispatch(updateStreak({streak: streak, maxStreak:maxStreak, lastStreakDate:todayDateOnly}))

            const analyticsData = createFinalObjArr(objects, incorrectSet.current, userId);
            console.log(analyticsData);
            axios.post(`${process.env.REACT_APP_BE_URL}/analytics`, { analyticsData })
                .then((response) => {
                    console.log('Analytics data posted successfully:', response.data);
                })
                .catch((error) => {
                    console.error('Error posting analytics data:', error);
                });


            console.log("Module progress added successfully.");
        } catch (error) {
            console.error('Error adding module progress:', error);
        }
    };

    // Handle completing quiz API
    const completeQuiz = async () => {
        const endTime = new Date(); // Get end time when module is finished
        const duration = (endTime - startTime) / 1000; // Calculate duration in seconds

        try {
            await axios.post(`${process.env.REACT_APP_BE_URL}/task/complete-quiz`, {
                userId,
                moduleId: selectedModule, // Assuming selectedModule is moduleId
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                duration
            });
            console.log("Quiz completed and duration logged successfully.");
        } catch (error) {
            console.error('Error completing the quiz:', error);
        }
    };

    // Handle the next question or completion
    const handleNext = useCallback(async (wasCorrect) => {
        let updatedIncorrect = incorrect;

        // If the answer is correct, remove the question from the incorrect list
        if (wasCorrect) {
            updatedIncorrect = incorrect.filter(obj => obj !== currentObject);
            setIncorrect(updatedIncorrect);
        }else  incorrectSet.current.add(currentObject._id);

        // If all questions are answered correctly, complete the module
        if (updatedIncorrect.length === 0) {
            await addModuleProgress();  // Add module progress
            await completeQuiz(); // Complete the quiz and log the duration

            dispatch(setModule(null));  // Reset module state and navigate to main content
            // alert("Congratulations! You have successfully completed the module.");
            setShowCompletionModal(true);
            // navigate('/maincontent');
        } else {
            // Move to the next question, even if the answer is incorrect
            pickRandomObject(updatedIncorrect);
        }
    }, [incorrect, currentObject, selectedSubtopic, selectedModule, userId, dispatch, navigate, pickRandomObject, addModuleProgress, completeQuiz]);


    return (
        <div className="module-page">
            <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
            {currentObject && currentObject.type === "MCQ" && (
                <MCQ 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "FIB" && (
                <FillInTheBlankQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "SingleCorrect" && (
                <SingleCorrect 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "match_the_following" && (
                <MatchTheFollowingQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
            {currentObject && currentObject.type === "multimatchquestion" && (
                <MultiMatchQuestion 
                    object={currentObject} 
                    progressBar={progress} 
                    onAnswerCheck={(wasCorrect) => handleNext(wasCorrect)}
                />
            )}
        {showCompletionModal && (
            <CompletionModal streak={streak} maxStreak={maxStreak} onClose={closeModal} />
        )}
        </div>
    );
};

export default ModulePage;
