import React, { useEffect, useState } from 'react';
import SidebarContainer from './SidebarContainer';
import ContentArea from './ContentArea';
import useFetchData from '../hooks/useFetchData';
import '../styles/MainContent.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSubject, setTopic, setSubtopic } from '../redux/actions/dataActions';
import Navbar from './Navbar';
import axios from 'axios';
// import { FaStickyNote } from 'react-icons/fa';
import Notes from './Notes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons';

const MainContent = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage the visibility of Sidebar
    const [isNotesOpen, setIsNotesOpen] = useState(false); // State to manage full-screen notes
    const [openNotesContent, setOpenNotesContent] = useState(null); // Content for the notes
    const selectedLevel = useSelector((state) => state.data.level);
    const selectedTopic = useSelector((state) => state.data.topic);
    const selectedSubject = useSelector((state) => state.data.subject);
    const [allNotes, setAllNotes] = useState({});

    const filters = {
        level: selectedLevel,
        subject: selectedSubject,
        topic: selectedTopic,
    };
    const { groupedData, modulesData, uniqueSubjects } = useFetchData(filters);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_URL}/guide`, {
                    params: filters,
                });
                setAllNotes(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleElementSelect = (element) => {
        dispatch(setSubtopic(element)); // Set subtopic in store
        const currentNote = allNotes.find((note) => note.subtopic === element);

        if (currentNote) {
            const contentLink = currentNote.contentLink;
            setOpenNotesContent(contentLink); // Set the content link for notes
            setIsNotesOpen(true); // Open the notes full-screen
            setIsSidebarOpen(false); // Hide the sidebar when notes are opened
        }
    };

    const handleToggleNotesSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
    };

    const handleCloseNotes = () => {
        setIsNotesOpen(false); // Close the Notes view
    };

    return (
        <div className="main-content-container relative" style={{ paddingTop: '70px' }}>
            <Navbar level={selectedLevel} subject={selectedSubject} topic={selectedTopic} />

            {/* Button to open the Notes Sidebar */}
            {!isNotesOpen && (
                <button
                    className="note-button fixed top-24 right-6 z-50 flex items-center text-white" // Adjusted 'top' value to bring it down
                    onClick={handleToggleNotesSidebar}
                >
                    {/* <FaStickyNote className="mr-2 text-2xl" /> */}
                    <FontAwesomeIcon icon={faBook} className="mr-2 text-2xl" />
                    <span>Notes</span>
                </button>
            )}

            {/* Main Content */}
            <div className={`main-content ${isNotesOpen ? 'hidden' : ''} ${isSidebarOpen ? 'main-content-sidebar-open' : ''}`}>
                <ContentArea topics={groupedData} subtopics={groupedData} modules={modulesData} />
            </div>

            {/* Sidebar for Notes */}
            {isSidebarOpen && !isNotesOpen && (
                <SidebarContainer
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={() => setIsSidebarOpen(false)}
                    groupedData={groupedData}
                    selectedTopic={selectedTopic}
                    onSelectElement={handleElementSelect}
                />
            )}

            {/* Full-Screen Notes */}
            {isNotesOpen && (
                <div className="fixed inset-0 bg-gray-900 text-white z-50 overflow-y-auto p-6">
                    {/* Single Close Button for Notes */}
                    <button
                        className="close-button"
                        onClick={handleCloseNotes}
                    >
                        &times;
                    </button>
                    <div className="w-full max-w-4xl mx-auto">
                        {openNotesContent && <Notes contentLink={openNotesContent} />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainContent;
