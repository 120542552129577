import React from 'react';

const CompletionModal = ({ streak, maxStreak, onClose }) => {
    const daysOfWeek = ["Su", "M", "T", "W", "Th", "F", "S"];

    // Get today's day index (0 for Sunday, 1 for Monday, ..., 6 for Saturday) in GMT
    const todayIndex = new Date().getUTCDay();
    // const todayIndex =5;
    // console.log(todayIndex);

    // Calculate highlighted days based on the streak and today's day
    const highlightedDays = Array(7).fill(false).map((_, index) => {
        const wStreak = todayIndex -  streak%7 ;
        return ( index > wStreak && index <= todayIndex);
    });

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 backdrop-blur-sm z-50 transition-opacity duration-300 ease-in-out">
            <div className="bg-gray-900 text-white rounded-lg shadow-2xl transform transition-all duration-500 ease-out scale-95 max-w-4xl w-2/3 p-8 animate-fadeInDown">
                <h2 className="text-2xl font-semibold text-center mb-4">Congratulations!</h2>
                <p className="text-gray-400 text-center mb-4">
                    You've successfully completed the module.
                </p>
                
                {/* Animated Streak Counter */}
                <div className="flex flex-col items-center mb-6">
                    <div className="text-6xl font-bold text-red-500 animate-fire mb-2">
                        🔥 {streak} 🔥
                    </div>
                    <p className="text-gray-400 text-center">
                        Current Streak: {streak} day{streak > 1 ? 's' : ''} (Max Streak: {maxStreak})
                    </p>
                </div>

                {/* Days of the Week Streak Display */}
                <div className="flex justify-center space-x-2 mt-4">
                    {daysOfWeek.map((day, index) => (
                        <div
                            key={index}
                            className={`text-center text-lg font-semibold w-8 h-8 flex items-center justify-center ${
                                highlightedDays[index] ? "bg-yellow-500 text-black flame-animate " : "bg-gray-700 text-white"
                            }`}
                        >
                            {day}
                        </div>
                    ))}
                </div>
                <div className="border-t-2 border-gray-600 mt-4 pt-4">
                    <p className="text-gray-400 text-center font-semibold mb-1">🌟 Daily practice = streak!</p>
                    <p className="text-gray-400 text-center font-semibold mb-1">⏳ Skip a day? Streak resets!</p>
                    <p className="text-gray-400 text-center font-semibold">🎉 Keep going for rewards!</p>
                </div>

                <div className="flex justify-center mt-6">
                    <button
                        onClick={()=>onClose()}
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-200"
                    >
                        close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CompletionModal;
