import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ErrorReportPopup from './ErrorReportPopUp';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ItemType = 'ANSWER';

const DraggableAnswer = ({ text, selectedAnswer, onSelectAnswer }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { text },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    // Determine if this item is selected
    const isSelected = selectedAnswer === text;

    return (
        <div
            ref={drag}
            onClick={() => onSelectAnswer(text)}  // Select the answer on click or touch
            className={`p-2 flex items-center justify-center rounded-lg cursor-pointer w-full ${
                isDragging ? 'opacity-50' : 'opacity-100'
            } ${isSelected ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}  // Change to blue when selected
            style={{
                minHeight: '80px',
                padding: '8px',  // Adjust padding to fit more text
                fontSize: '0.875rem',  // Slightly smaller font size
                wordWrap: 'break-word',  // Allow text to wrap to next line
                whiteSpace: 'normal',  // Ensure text wraps within the box
            }}
        >
            {text}
        </div>
    );
};

const DroppableArea = ({ acceptedAnswer, onDrop, index, selectedDropIndex, onTouchDrop, isCorrect, isIncorrect }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemType,
        drop: (item) => {
            if (!acceptedAnswer) {  // Only drop if no answer is present
                onDrop(item, index);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isEmpty = !acceptedAnswer;
    const isActive = selectedDropIndex === index;

    return (
        <div
            ref={drop}
            onClick={() => onTouchDrop(index)}
            className={`p-2 rounded-lg w-full h-12 flex items-center justify-center border-2 ${
                isCorrect ? 'border-green-500' : isIncorrect ? 'border-red-500' : 'border-gray-300'
            } ${isActive ? 'bg-blue-500 text-white' : isEmpty ? 'bg-gray-700 text-gray-400' : 'bg-gray-800 text-white'}`}
            style={{
                minHeight: '80px',
                padding: '6px',  // Adjust padding to fit more text
                fontSize: '0.875rem',  // Slightly smaller font size
                wordWrap: 'break-word',  // Allow text to wrap to next line
                whiteSpace: 'normal',  // Ensure text wraps within the box
            }}
        >
            {acceptedAnswer || 'Tap to place your answer'}
        </div>
    );
};

const MultiMatchQuestion = ({ object, progressBar, onAnswerCheck }) => {
    const { question, options, answer } = object;
    const [droppedAnswers, setDroppedAnswers] = useState(Array(answer.length).fill(null));
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [correctIndexes, setCorrectIndexes] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const userId = useSelector((store)=>store.data.userId);;
    const [selectedAnswer, setSelectedAnswer] = useState(null);  // State to store selected answer
    const [selectedDropIndex, setSelectedDropIndex] = useState(null);  // State to store selected drop area

    useEffect(() => {
        setDroppedAnswers(Array(answer.length).fill(null)); // Reset dropped answers
        setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle unique answers
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
        setSelectedAnswer(null);  // Reset selected answer
        setSelectedDropIndex(null);  // Reset selected drop area
    }, [options, answer]);

    const shuffleArray = (array) => {
        let shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleDrop = (item, index) => {
        const updatedDroppedAnswers = [...droppedAnswers];
        updatedDroppedAnswers[index] = item.text;
        setDroppedAnswers(updatedDroppedAnswers);
    };

    const handleTouchDrop = (index) => {
        setSelectedDropIndex(index);
        if (selectedAnswer) {
            const updatedDroppedAnswers = [...droppedAnswers];
            updatedDroppedAnswers[index] = selectedAnswer;
            setDroppedAnswers(updatedDroppedAnswers);
            setSelectedAnswer(null);
        }
    };

    const handleCheckAnswer = () => {
        let correct = [];
        droppedAnswers.forEach((droppedAnswer, index) => {
            const cleanedAnswer = droppedAnswer?.toLowerCase().replace(/\s+/g, '');
            const cleanedCorrectAnswer = object.answer[index]?.toLowerCase().replace(/\s+/g, '');
            if (cleanedAnswer === cleanedCorrectAnswer) {
                correct.push(index);
            }
        });
        setCorrectIndexes(correct);
        setIsChecked(true);
        setFeedback(correct.length === options.length);
        setShowNextButton(true);
    };

    const handleReset = () => {
        setDroppedAnswers(Array(answer.length).fill(null));
        setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle answers on reset
        setIsChecked(false);
        setCorrectIndexes([]);
        setFeedback(false);
        setShowNextButton(false);
        setSelectedAnswer(null);  // Reset selected answer
        setSelectedDropIndex(null);  // Reset selected drop area
    };

    const handleNextQuestion = () => {
        const correct = droppedAnswers.every((droppedAnswer, index) => droppedAnswer === answer[index]);
    
        if (correct) {
            onAnswerCheck(true);
        } else {
            onAnswerCheck(false);
            setIsChecked(false);
            setCorrectIndexes([]);
            setDroppedAnswers(Array(answer.length).fill(null));
            setShowNextButton(false);
        }
    
        // // Reset states for the next question
        // setDroppedAnswers(Array(answer.length).fill(null));
        // setShuffledAnswers(shuffleArray([...new Set(answer)])); // Shuffle answers for the next question
        // setShowNextButton(false);
        // setIsChecked(false);
        // setCorrectIndexes([]);
        // setFeedback(false);
    };

    const handleReportSubmit = async (reportText, questionObject) => {
        try {
            axios.post(`${process.env.REACT_APP_BE_URL}/task/report-mistake`, { report: reportText, question: questionObject , userId: userId});
            alert("Report has been submitted successfully, we will look into this as soon as possible! Thank you")
        } catch (error) {
            console.error("Error submitting report:", error);
        }
    };

    let imageIndex = 0;
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="p-5 text-center bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center">
                <div className="absolute top-5 right-5">
                    <button onClick={() => setIsPopupOpen(true)} className="text-yellow-500 text-2xl">
                        ❗
                    </button>
                </div>

                <ErrorReportPopup
                    isOpen={isPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                    onSubmit={handleReportSubmit}
                    questionObject={object}
                />

                <div className="w-2/3 bg-gray-200 rounded-full h-2.5 mb-4 absolute top-10">
                    <div className="bg-green-600 h-2.5 rounded-full" style={{ width: `${progressBar}%` }}> </div>
                </div>

                <div className="w-full max-w-4xl">
                    <div className="mb-5">
                        <div className="text-lg font-semibold mb-2">
                            {object.question.split(/({image\d+})/g).map((part, index) => {
                                if (part.match(/{image\d+}/)) {
                                    const match = part.match(/{image(\d+)}/);
                                    if (match) {
                                        const imageKey = imageIndex;
                                        const imageUrl = object.image ? object.image[imageKey] : null;
                                        if (imageUrl) {
                                            const imageElement = (
                                                <img
                                                    key={`image-${imageIndex}`}
                                                    src={imageUrl}
                                                    alt={`Image ${imageKey}`}
                                                    className="inline-block mx-2"
                                                />
                                            );
                                            imageIndex += 1;
                                            return imageElement;
                                        }
                                    }
                                } else {
                                    return part;
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mb-5">
                        {/* Column 1: Items */}
                        <div className="flex flex-col space-y-2">
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    className={`p-2 bg-gray-700 text-white rounded-lg border-2 w-full ${
                                        isChecked && correctIndexes.includes(index) ? 'border-green-500' : ''
                                    }`}
                                    style={{ minHeight: '70px' }}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>

                        {/* Column 2: Drop Area */}
                        <div className="flex flex-col space-y-2">
                            {options.map((_, index) => (
                                <DroppableArea
                                    key={index}
                                    acceptedAnswer={droppedAnswers[index]}
                                    onDrop={(item) => handleDrop(item, index)}
                                    index={index}
                                    onTouchDrop={handleTouchDrop}
                                    selectedDropIndex={selectedDropIndex}
                                    isCorrect={isChecked && correctIndexes.includes(index)}
                                    isIncorrect={isChecked && !correctIndexes.includes(index)}
                                />
                            ))}
                        </div>

                        {/* Column 3: Draggable Answers */}
                        <div className="flex flex-col space-y-2">
                            {isChecked ? (
                                answer.map((ans, index) => (
                                    <div
                                        key={index}
                                        className={`p-2 bg-gray-700 text-white rounded-lg border-2 flex items-center justify-center ${
                                            correctIndexes.includes(index)
                                                ? 'border-green-500 bg-green-400 font-medium text-black'
                                                : 'border-red-500 bg-red-400 font-medium text-black'
                                        }`}
                                        style={{ minHeight: '70px' }}
                                    >
                                        {ans}
                                    </div>
                                ))
                            ) : (
                                shuffledAnswers.map((ans, index) => (
                                    <DraggableAnswer
                                        key={index}
                                        text={ans}
                                        selectedAnswer={selectedAnswer}
                                        onSelectAnswer={setSelectedAnswer}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
                {/* Feedback and Controls */}
                <div className="mt-5 flex-col space-x-4 justify-center items-center bottom-14 fixed">
                        {feedback && progressBar === 100 && <p className="text-green-500 mb-4 font-semibold">Congratulations! You have completed the module. <br /> Redirecting to the main page ..</p>}
                        {feedback && progressBar !== 100 && <p className="text-green-500 mb-4 font-semibold">Great job!</p>}
                        {!feedback && isChecked && <p className="text-red-500 mb-4 font-semibold">Incorrect!</p>}
                        {!showNextButton && (
                            <button
                                className="px-6 py-2 bg-amber-600 text-white font-semibold rounded-lg hover:bg-amber-700"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                        )}
                        {!showNextButton ? (
                            <button
                                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                                onClick={()=>handleCheckAnswer()}
                            >
                                Check
                            </button>
                        ) : (
                            <button
                                className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                                onClick={()=>handleNextQuestion()}
                            >
                                Next
                            </button>
                        )}
                        
                    </div>
            </div>
        </DndProvider>
    );
};

export default MultiMatchQuestion;
